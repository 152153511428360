import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { ConfirmDialog } from '@neptune/components';
import { ChangePasswordDialog } from '@neptune/components/change-password-dialog/change-password-dialog';
import { LoadingModalService } from '@neptune/components/loading-modal/loading-modal.service';
import { UserProfileDialogComponent } from '@neptune/components/user-profile-dialog/user-profile-dialog.component';
import { OrgModel, Roles, UserData } from '@neptune/models';
import { Asset, AssetUtils, QueryParam, AssetType } from '@neptune/models/asset';
import { TimezoneUtils } from '@neptune/models/timezone';
import { AccountService } from '@neptune/services/account.service';
import { AssetService } from '@neptune/services/asset.service';
import { NavigatableComponent, NavigationQueueService } from '@neptune/services/navigation-queue.service';
import { OrgService } from '@neptune/services/org.service';
import { UserService } from '@neptune/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { navigation, SetNavigateStateType } from 'app/navigation/navigation';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APIConfig, SisenseDashs } from 'environments/environment';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy, NavigatableComponent {
  // CUSTOM @NEPTUNE VARIABLES
  Roles = Roles;
  userData: UserData;
  fullName: string;
  organizationName: string;
  avatarPath = '';
  navigationQueueEnabled: boolean = false;
  impersonationOrgOptions: OrgModel[] = [];
  impersonationOrgsLoaded: boolean = false;
  searchResultPath = '/pages/search-result';
  autoResults: any[];
  typingTimer: any;

  // FUSE VARIABLES
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  searchConfig: any;
  showAuto: boolean;
  loading: boolean;
  currentRole: Roles;
  isGlobalAdmin: boolean = false;
  userName: string;
  orgId: string;

  // Private
  private _unsubscribeAll: Subject<void>;
  public isC3user = false;
  public urlLogout = SisenseDashs.SisenseUrl + '/api/auth/logout';
  public logoutframe = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private accountService: AccountService,
    private userService: UserService,
    private organizationService: OrgService,
    private changeDetector: ChangeDetectorRef,
    private matDialog: MatDialog,
    private sidebarService: FuseSidebarService,
    private assetService: AssetService,
    private navigationQueueService: NavigationQueueService,
    private router: Router,
    private loadingModalService: LoadingModalService
  ) {
    this._initializeNavigationQueue();
    this._initializeAccount();

    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.selectedLanguage = this.languages[0];

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // search stuff
    this.searchConfig = {
      searchValue: '',
      searchPlaceholder: 'Search for an item'
    };

    this.autoResults = [];
    this.showAuto = false;
    this.loading = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Neptune Custom Methods
  // -----------------------------------------------------------------------------------------------------

  navigateBack() {
    this.navigationQueueService.back();
  }

  private _initializeNavigationQueue() {
    this.navigationQueueService.navigationStatusChanged().subscribe((active: boolean) => {
      this.navigationQueueEnabled = active;
      this.changeDetector.markForCheck();
    });
  }

  private _initializeAccount() {
    this.accountService
      .getCurrentUser()
      .then(
        userData => {
          this.userData = userData;
          userData.attributes['username'] = userData.username || userData.attributes['email'];

          userData.attributes['org'] = userData.attributes['qp_orgId'];
          userData.attributes['role'] = userData.attributes['qp_role'];
          userData.attributes['timezone'] = userData.attributes['qp_timezone'];
          TimezoneUtils.currentTimezoneName = userData.attributes['timezone'] as string;
          this.isC3user =
            userData.attributes['role'] === Roles.C3USER ? (this.isC3user = true) : (this.isC3user = false);

          this.fullName = userData.attributes['given_name'] + ' ' + userData.attributes['family_name'];
          this.organizationName = userData.org['orgName'] as string;

          this.currentRole = userData.attributes['qp_role'] as Roles;
          this.isGlobalAdmin = JSON.parse(userData.attributes['systemManagement'] as string);
          this.userName = userData.username || (userData.attributes['email'] as string);
          this.orgId = userData.attributes['qp_orgId'] as string;

          this.changeDetector.markForCheck();

          if (userData.attributes['avatar_path']) {
            this.userService.getUserAvatar(userData.attributes.email as string).subscribe((imageData: any) => {
              userData.attributes['avatar'] = imageData.image;

              if (this.userData.attributes['avatar'] && this.userData.attributes['avatar'].length > 0) {
                this.avatarPath = this.userData.attributes['avatar'];
              }

              this.changeDetector.markForCheck();
            });
          }

          this.loadOrganizations();
          this.validRole(this.userData);
        },
        error => {}
      )
      .catch(err => {});
  }

  openHelpWindow() {
    (window as any)._elev.openHome();
  }

  loadOrganizations() {
    if (this.impersonationOrgOptions.length === 0) {
      this.organizationService.getAllAdminOrganizations(true).subscribe((orgs: OrgModel[]) => {
        if (!this.isGlobalAdmin) {
          // if the logged user is not global admin, we overwrite the orgs with
          // only the ones accesible by the logged user
          // TODO: Maybe fetch organizations once?, use user ID instead of name?
          this.userService.getOrganizations(this.userName).subscribe(userOrgs => {
            // eslint-disable-next-line arrow-body-style
            let organizations = userOrgs.map(x => {
              return {
                OrgID: x.orgId
              };
            });

            organizations = organizations.filter(m => m.OrgID !== this.orgId);
            orgs = organizations as any;
          });
        }

        this.impersonationOrgsLoaded = true;
        // filter out the current organization, and all inactive ones, then order alphabetically
        this.impersonationOrgOptions = orgs
          .filter(m => m.OrgID !== this.orgId && m.Active)
          .sort((a, b) => a.OrgID.localeCompare(b.OrgID));
        this.changeDetector.markForCheck();
      });
    }
  }

  switchToOrganization(org: OrgModel) {
    ConfirmDialog.open(
      this.matDialog,
      {
        title: 'Organization Switching',
        message: `You are about to switch to ${org.Name || org.OrgID}. Are you sure?`,
        okMessage: 'Switch',
        cancelMessage: 'Cancel'
      },
      () => {
        this.loadingModalService.startLoading();
        this.userService.adminImpersonationOrg(org).subscribe({
          next: () => {
            this.accountService.refreshSession().subscribe({
              next: (data: any) => {
                this.organizationService.getOrganization(data.defaultOrg).subscribe({
                  next: (orgModel: OrgModel) => {
                    this.accountService.setOrgData(
                      {
                        ...orgModel,
                        defaultOrg: data.defaultOrg
                      },
                      this.accountService.cognitoService.username
                    );
                    this.logoutframe = true;
                    const expireDate = 'Thu, 01 Jan 1970 00:00:00 UTC';
                    if (APIConfig.EnvName === 'prod') {
                      document.cookie = 'app_token=; expires=' + expireDate + `; domain=.nxtdrive.app; path=/`;
                    } else {
                      document.cookie =
                        'app_token=; expires=' + expireDate + `; domain=.${APIConfig.EnvName}.nxtdrive.app; path=/`;
                    }
                    window.location.href = '/pages/homepage';
                  },
                  error: error => this.loadingModalService.stopLoading()
                });
              },
              error: error => this.loadingModalService.stopLoading()
            });
          },
          error: error => this.loadingModalService.stopLoading()
        });
      }
    );
  }

  logout() {
    this.logoutframe = true;
    const expireDate = 'Thu, 01 Jan 1970 00:00:00 UTC';
    if (APIConfig.EnvName === 'prod') {
      document.cookie = 'app_token=; expires=' + expireDate + `; domain=.nxtdrive.app; path=/`;
    } else {
      document.cookie = 'app_token=; expires=' + expireDate + `; domain=.${APIConfig.EnvName}.nxtdrive.app; path=/`;
    }
    setTimeout(() => {
      this.accountService.logout();
      location.reload();
    }, 200);
  }

  changePassword() {
    this.matDialog.open(ChangePasswordDialog, {
      data: this.userData.attributes
    });
  }

  editProfile() {
    const dialogRef = this.matDialog.open(UserProfileDialogComponent, {
      data: this.userData.attributes
    });

    dialogRef.componentInstance.onUserDataChanged.subscribe(userData => {
      this.userData.attributes = userData;

      if (this.userData.attributes['timezone']) {
        TimezoneUtils.currentTimezoneName = this.userData.attributes['timezone'];
      }

      if (this.userData.attributes['avatar'] && this.userData.attributes['avatar'].length) {
        this.avatarPath = this.userData.attributes['avatar'];
      }

      this.fullName = this.userData.attributes['given_name'] + ' ' + this.userData.attributes['family_name'];
      this.organizationName = this.userData.org['orgName'] as string;
      this.changeDetector.markForCheck();
    });
  }

  toggleSidebarOpened(key: any) {
    this.sidebarService.getSidebar(key)?.toggleOpen();
  }

  // ------------------------------------------------
  validRole(udat: UserData): void {
    const user_rol = udat.attributes.role;
    const system_management = udat.attributes.systemManagement;
    this.navigation = navigation;
    const adminNav: any = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.navigation.length; i++) {
      if (this.navigation[i].roles[0] === 'all') {
        adminNav.push(this.navigation[i]);
      }
      if (user_rol === 'Admin' && system_management === 'false') {
        if (this.navigation[i].roles[0] === 'admin') {
          adminNav.push(this.navigation[i]);
        }
      }
      if (user_rol === 'Admin' && system_management === 'true') {
        if (this.navigation[i].roles[0] === 'admin' || this.navigation[i].roles[0] === 'system') {
          adminNav.push(this.navigation[i]);
        }
      }
    }

    // Register the new navigation
    this._fuseNavigationService.onNavigationRegistered.subscribe(m => {
      if (m[0] !== 'admin-nav') {
        this._fuseNavigationService.register('admin-nav', adminNav);
        // Set the current navigation
        this._fuseNavigationService.setCurrentNavigation('admin-nav');
      }
    });
    // this._fuseNavigationService.unregister('admin-nav');
    // this._fuseNavigationService.register('admin-nav', adminNav);
    // Set the current navigation
    // this._fuseNavigationService.setCurrentNavigation('admin-nav');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings: any) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: any): void {
    this._fuseSidebarService.getSidebar(key)?.toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value: string): void {
    clearInterval(this.typingTimer);
    this.showAuto = false;

    this.router.navigateByUrl('/pages/search-results', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.searchResultPath], {
        queryParams: {
          searchText: encodeURIComponent(value)
        }
      });
    });
  }

  onSearchChange(value: string): void {
    // this.searchField = value;
    clearInterval(this.typingTimer);
    this.autoResults = [];
    if (value.length > 0) {
      this.showAuto = true;
      this.loading = true;
      this.typingTimer = setTimeout(() => this.autoSearch(value), 500);
    } else {
      this.showAuto = false;
      this.changeDetector.markForCheck();
    }
  }

  autoSearch(value: string) {
    this.assetService.getRecentAsset(5, value).subscribe(data => {
      if (typeof data === 'string') {
        this.autoResults = [{ name: 'Nothing Found.' }];
      } else {
        this.autoResults = data;
      }
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }

  navigateToAsset(asset: Asset) {
    this.showAuto = false;
    this.navigationQueueService.navigateTo([AssetUtils.getAssetURL(asset.assetType as AssetType)], this, {
      queryParams: { ...this.getRowQueryParams(asset) }
    });
  }

  getRowQueryParams(row: Asset) {
    const queryParams = AssetUtils.getAssetParams(row.assetType as AssetType).reduce((acc: any, curr: QueryParam) => {
      const prop = AssetUtils.getQueryParamAssetProp(curr);
      acc[curr] = row[prop];
      return acc;
    }, {});

    return queryParams;
  }

  getIconForAsset(row: Asset) {
    return AssetUtils.getAssetIcon(row.assetType as AssetType);
  }

  // NavigatableComponent Interface
  public setNavigateState(state: SetNavigateStateType) {}

  public getNavigateState() {
    return {};
  }

  public get componentType(): string {
    return 'SearchResultsPageComponent';
  }
  //

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang: any): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }
}
